
.floating-bubbles {
    position: absolute;
    left:0;
    top:0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.floating-bubbles-background {
    position: absolute;
    left:0;
    top:0;
    width: 100vw;
    height: 100vh;
    background: url('../img/login_bg.jpg');
    opacity: 0.3;
}

$particleSize: 30vmin;
$animationDuration: 16s;
$amount: 20;
.floating-bubbles span {
    width: $particleSize;
    height: $particleSize;
    border-radius: $particleSize;
    backface-visibility: hidden;
    position: absolute;
    animation-name: move;
    animation-duration: $animationDuration;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    $colors: (
        #086788,
        #07a0c3
    );
    @for $i from 1 through $amount {
        &:nth-child(#{$i}) {
            color: nth($colors, random(length($colors)));
            top: random(100) * 1%;
            left: random(100) * 1%;
            animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
            animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
            transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
            $blurRadius: (random() + 0.5) * $particleSize * 0.5;
            $x: if(random() > 0.5, -1, 1);
            box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
        }
    }
}

@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}
